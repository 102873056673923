/* ----- MEDIA QUERIES ----- */
@use "sass:math";

@mixin bp-320 {
	@media screen and (max-width: 320px) {
		@content;
	}
}
@mixin bp-370 {
	@media screen and (max-width: 370px) {
		@content;
	}
}
@mixin bp-767 {
	@media screen and (max-width: 768px), screen and (orientation: landscape) and (max-width: 896px) and (hover: none) {
		@content;
	}
}
@mixin bp-768 {
	@media screen and (min-width: 768px) {
		@content;
	}
}
@mixin bp-959 {
	@media screen and (max-width: 959px) {
		@content;
	}
}
@mixin bp-1024 {
	@media screen and (max-width: 1024px) {
		@content;
	}
}
@mixin bp-1200 {
	@media screen and (max-width: 1200px) {
		@content;
	}
}
@mixin bp-1440 {
	@media screen and (max-width: 1440px) {
		@content;
	}
}
@mixin bp-min-1441 {
	@media screen and (min-width: 1441px) {
		@content;
	}
}
@mixin bp-min-1681 {
	@media screen and (min-width: 1681px) {
		@content;
	}
}
@mixin bp-1680 {
	@media screen and (max-width: 1680px) {
		@content;
	}
}
@mixin bp-1920 {
	@media screen and (max-width: 1920px) {
		@content;
	}
}

@mixin bp-xxl {
	@media screen and (min-width: 2200px) {
		@content;
	}
}

// Viewport width of design
$reference-width: 1512;
$vw-context: ($reference-width * 0.01) * 1px;

/* ----- SIZE ----- */
@mixin vw($property, $desktop-size, $mobile-size: false, $max-size: false) {
	//Desktop size
	#{$property}: math.div($desktop-size, $vw-context) * 1vw;

	// Mobile size
	@if $mobile-size {
		$bp: math.div($reference-width * strip-unit($mobile-size), strip-unit($desktop-size)) * 1px;
		$roundedBp: round($bp);

		@media screen and (max-width: $roundedBp) {
			#{$property}: $mobile-size;
		}
	}

	// Max size
	@if $max-size {
		$bp: $reference-width * math.div(strip-unit($max-size), strip-unit($desktop-size)) * 1px;
		$roundedBp: round($bp);

		@media screen and (min-width: $roundedBp) {
			#{$property}: $max-size;
		}
	}
}

@function strip-unit($number) {
	@if type-of($number) == "number" and not unitless($number) {
		@return math.div($number, $number * 0 + 1);
	}

	@return $number;
}

@function calc-vh($quantity) {
	@return calc(var(--vh, 1vh) * #{$quantity});
}

/* ----- Hover only on non-touch devices ----- */
@mixin hover() {
	@media (any-hover: hover) {
		&:hover {
			@content;
		}
	}
}

@mixin removeScrollbar() {
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}
