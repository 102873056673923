@import '../../scss_critical/mixins.scss';

nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 5;
  overflow: hidden;

  @include vw(margin-top, 42px, 32px);
  @include vw(padding-left, 42px, 24px);
  @include vw(padding-right, 42px, 24px);

  a {
    @include sans-text-m;
    text-transform: uppercase;
    transform: translateY(108%) rotate(10deg);
  }

  @include bp-767 {
    position: relative;
    justify-content: center;
    gap: 8px;
  }
}
