body {
	&[data-theme="black"] {
		background-color: var(--black);

		::-moz-selection {
			background: var(--white);
			color: var(--black);
		}

		::selection {
			background: var(--white);
			color: var(--black);
		}

		* {
			color: var(--white);
		}

		.c-scrollbar_thumb {
			opacity: 0.35;
			background-color: var(--white);
		}
	}


	.c-scrollbar_thumb {
		opacity: 0.65;
		background-color: var(--black);
	}
}
