@import '../../scss_critical/mixins.scss';

#video-player {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: none;

  background-color: var(--black);
  transition: clip-path 1s var(--ease-in-out-quint);
  clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);

  .top,
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include vw(padding-left, 26px, 26px);
    @include vw(padding-right, 26px, 26px);
    z-index: 2;
    transition: opacity 0.25s linear;
    mix-blend-mode: difference;
  }

  .top {
    gap: 16px;
    @include vw(padding-top, 34px, 26px);
  }

  .bottom {
    @include vw(gap, 42px);
    @include vw(padding-bottom, 34px, 26px);

    @include bp-767 {
      &.mobile-only {
        display: flex !important;
      }
    }
  }

  .title {
    flex-grow: 1;

    @include bp-767 {
      text-align: center;
    }
  }

  h2,
  p,
  button,
  a {
    color: var(--white);
    @include sans-headline-m;
    transition: transform 1s var(--ease-in-out-quint);
    transform: translateY(108%);
    user-select: none;
  }

  h2 {
    @include bp-767 {
      line-height: 1.1;
    }
  }

  a {
    display: inline-block;
  }

  .time {
    @include vw(width, 120px);

    .delimiter {
      display: inline-block;
      transform: translateY(-7%);
    }
  }

  .fullscreen {
    @include vw(width, 240px);
    justify-content: flex-end;
    display: flex;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0;
    cursor: pointer;
    pointer-events: none;

    &.is-visible {
      opacity: 1;
      pointer-events: auto;
    }

    @include bp-767 {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.is-visible {
    pointer-events: auto;
    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);

    h2,
    p,
    button,
    a {
      transform: translateY(0);
      transition: transform 1s var(--ease-out-expo)
        var(--transition-delay);
      user-select: initial;

      @include bp-767 {
        transition: transform 1s var(--ease-out-expo) 0.4s;
      }
    }

    .slider {
      input {
        transform: translateX(0);
        transition: transform 1.4s var(--ease-out-expo)
            var(--transition-delay),
          opacity 0.25s linear;
      }
    }
  }

  &.is-ui-hidden {
    .top,
    .bottom {
      opacity: 0;
    }
  }

  .slider {
    flex-grow: 1;
    height: 100%;
    display: flex;
    justify-content: center;

    label {
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }

    input {
      width: 100%;
      transform: translateX(-102%);
      transition: transform 1.4s var(--ease-out-expo);

      &::-webkit-slider-runnable-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        background: var(--white);
      }
      &::-webkit-slider-thumb {
        height: 22px;
        width: 2px;
        background: var(--white);
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -11px;
      }
    }
  }
}
