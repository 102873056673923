@import "../../scss_critical/mixins.scss";
@import "../typography.scss";

.impressum-hero {
	@include bp-767 {
		h1 {
			font-size: 15.5vw;
		}
	}
}

.impressum-text {
	max-width: 100%;
	padding: 0 20px;
	@include vw(width, 586px, 560px);
	text-align: center;
	overflow-wrap: break-word;

	p,
	a {
		@include sans-text-m;
	}

	h3 {
		@include sans-headline-m;
		max-width: 80%;
		margin-left: auto;
		margin-right: auto;
		@include vw(margin-bottom, 40px, 24px);
	}

	p {
		@include vw(margin-bottom, 178px, 100px);
	}
}
