.grain {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
	overflow: hidden;
	pointer-events: none;
	opacity: 1;
	transition: opacity 0.5s 0.1s;

	&:after {
		content: "";
		position: fixed;
		top: -200vh;
		left: -200vw;
		width: 400vw;
		height: 400vh;
		background-image: url("https://images.prismic.io/oliverbernotat/2e235e27-2810-4657-9584-3d91250c1123_grain.png?auto=compress,format");
		opacity: 0.22;
		animation: grain 10s steps(10) infinite;
	}
}

@keyframes grain {
	0%,
	100% {
		transform: translate(0, 0);
	}

	10% {
		transform: translate(-3%, -8%);
	}

	20% {
		transform: translate(-12%, 6%);
	}

	30% {
		transform: translate(10%, -20%);
	}

	40% {
		transform: translate(-8%, 20%);
	}

	50% {
		transform: translate(-14%, 12%);
	}

	60% {
		transform: translate(16%, 2%);
	}

	70% {
		transform: translate(1%, 13%);
	}

	80% {
		transform: translate(4%, 30%);
	}

	90% {
		transform: translate(-7%, 8%);
	}
}
