@import '../scss_critical/mixins.scss';
@import '../scss_critical/variables.scss';

/**
	Sans Serif Styles
*/
@mixin sans-headline-xxl($lineHeight: 0.82) {
  text-transform: uppercase;
  letter-spacing: -0.03em;
  line-height: $lineHeight;
  @include vw(font-size, 210px);

  @include bp-767 {
    font-size: 17vw;
  }
}

@mixin sans-headline-xl($lineHeight: 1.1) {
  text-transform: uppercase;
  font-family: Untitled, sans-serif;
  letter-spacing: -0.02em;
  line-height: $lineHeight;
  @include vw(font-size, 82px, 48px);
}

@mixin sans-headline-ll($lineHeight: 1.1) {
  text-transform: uppercase;
  font-family: Untitled, sans-serif;
  line-height: $lineHeight;
  @include vw(font-size, 60px, 42px);
}

@mixin sans-headline-sl($lineHeight: 1.1) {
  text-transform: uppercase;
  font-family: Untitled, sans-serif;
  line-height: $lineHeight;
  @include vw(font-size, 38px, 28px);
}

@mixin sans-headline-l($lineHeight: 1.1) {
  text-transform: uppercase;
  font-family: Untitled, sans-serif;
  line-height: $lineHeight;
  @include vw(font-size, 28px, 24px);
}

@mixin sans-headline-m($lineHeight: 1) {
  text-transform: uppercase;
  font-family: Untitled, sans-serif;
  line-height: $lineHeight;
  @include vw(font-size, 22px, 20px);
}

@mixin sans-text-m($lineHeight: 1.4) {
  font-family: Untitled, sans-serif;
  line-height: $lineHeight;
  @include vw(font-size, 16px, 13px);
}

/**
	Serif Styles
*/
@mixin serif-headline-l($lineHeight: 1) {
  font-family: Soehne, serif;
  line-height: $lineHeight;
  text-transform: uppercase;
  font-feature-settings: 'calt', 'clig', 'kern', 'liga', 'case';
  @include vw(font-size, 39px, 28px);
}

@mixin serif-headline-m($lineHeight: 1) {
  font-family: Soehne, serif;
  line-height: $lineHeight;
  text-transform: uppercase;
  font-feature-settings: 'calt', 'clig', 'kern', 'liga', 'case';
  @include vw(font-size, 34px, 28px);
}

@mixin serif-headline-s($lineHeight: 1) {
  font-family: Soehne, serif;
  line-height: $lineHeight;
  text-transform: uppercase;
  font-feature-settings: 'calt', 'clig', 'kern', 'liga', 'case';
  @include vw(font-size, 23px, 20px);
}
