@import '../../scss_critical/mixins.scss';

.hero {
  @include vw(padding-left, 42px, 16px);
  @include vw(padding-right, 42px, 16px);
  min-height: 80vh;
  display: grid;
  place-items: center;
  z-index: 4;
  @include removeScrollbar();

  .content-wrapper {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  h1 {
    text-align: center;
    @include sans-headline-xxl;
  }

  @include bp-767 {
    min-height: 70vh;
  }
}

.hero-el {
  h2 {
    @include serif-headline-s;
  }

  a,
  p,
  span {
    @include serif-headline-l;
  }

  span {
    display: flex;
  }
}

.hero-subhead {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @include vw(column-gap, 14px, 8px);
  @include vw(row-gap, 14px);
  @include vw(margin-top, 8px, 8px);

  @include bp-767 {
    row-gap: 0;
  }
}

.hero-el {
  display: grid;
  grid-auto-flow: column;
  align-items: baseline;
  @include vw(gap, 3px, 3px);

  span {
    display: flex;
  }
}

.hero-small {
  position: relative;
  z-index: 4;
  @include vw(padding-left, 42px, 16px);
  @include vw(padding-right, 42px, 16px);
  @include vw(margin-bottom, 42px, 16px);
  min-height: 30vh;
  display: grid;
  place-items: center;
  @include removeScrollbar();

  h1 {
    text-align: center;
    @include sans-headline-ll(0.9);
  }
}
