@import '../scss_critical/mixins.scss';
@import './typography.scss';

section {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
}

.overflow-hider {
  overflow: hidden;
  height: fit-content;
}

.mobile-only {
  display: none !important;

  @include bp-767 {
    display: block !important;
  }
}

.desktop-only {
  @include bp-767 {
    display: none !important;
  }
}

:fullscreen {
  background-color: #000;
}

.hero,
.hero-small {
  .content-wrapper {
    transform: translateX(100%) rotate(10deg);
    transform-origin: 50% 0;
    transition: transform 1s var(--ease-in-out-quart);
  }

  span {
    display: inline-block;
  }

  .intro-el {
    transform: translateY(108%) rotate(18deg);
  }
}

body {
  &.is-loaded {
    .hero,
    .hero-small {
      .content-wrapper {
        transform: translateX(0);
      }
    }

    .intro-el {
      transform-origin: 0% 0%;
      transition: transform 0.8s var(--ease-in-out-quart)
        var(--transition-delay);
      transform: translateY(0) rotate(0);
    }

    nav a {
      transform: translateY(0);
      transition: transform 1s var(--ease-in-out-quart);

      &:first-child {
        transition-delay: 0.4s;
      }

      &:nth-child(2) {
        transition-delay: 0.45s;
      }
    }
  }
}

@import './video.scss';
@import './components/image.scss';
@import './components/footer.scss';
@import './components/nav.scss';
@import './components/hero.scss';
@import './components/grain.scss';
@import './components/video-player.scss';
@import './components/theme.scss';
@import './pages/home.scss';
@import './pages/project.scss';
@import './pages/impressum.scss';
