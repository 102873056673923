@import "../../scss_critical/mixins.scss";

footer {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	@include vw(column-gap, 24px, 8px);
	@include vw(row-gap, 14px, 2px);
	@include vw(padding-left, 24px, 24px);
	@include vw(padding-right, 24px, 24px);

	.footer-el {
		display: flex;
		align-items: baseline;
		flex-shrink: 0;
		@include vw(gap, 6px, 3px);
	}

	h3 {
		@include serif-headline-s;
	}

	a {
		@include serif-headline-l;
	}

	span {
		display: flex;
		@include serif-headline-l;
	}
}
