@import '../../scss_critical/mixins.scss';
@import '../typography.scss';

.home-projects {
  z-index: 3;
  padding-top: 100vh;
  margin-top: -100vh;
  overflow: hidden;
  padding-bottom: 100vh;
  @include vw(--margin-bottom, 200px, 140px);
  margin-bottom: calc(-50vh + var(--margin-bottom));

  @include bp-767 {
    padding-bottom: 0;
    margin-bottom: var(--margin-bottom);
  }

  .project-grid {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include vw(gap, 40px, 30px);
    width: 100%;
    overflow: visible;
    transition: transform 1s var(--ease-in-out-quart);
  }

  .project {
    position: relative;
    display: inline-block;
    @include vw(width, 1093px);
    @include vw(height, 613px);
    will-change: transform;
    transition: transform 0.08s linear;

    @include bp-767 {
      width: 90%;
      height: auto;
    }

    @include hover {
      h2 {
        transform: translateY(0) !important;
      }

      .image {
        &:after {
          opacity: 0.45 !important;
        }
      }
    }

    h3 {
      @include sans-headline-l;
    }

    h2 {
      @include sans-headline-sl(0.9);

      @include bp-767 {
        transform: translateY(0) !important;
      }
    }

    h2,
    h3 {
      color: var(--white);

      @include bp-767 {
        color: var(--text-color);
        font-size: 18px;
        transition: color 0.3s linear !important;
        mix-blend-mode: initial;
      }
    }

    .image {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.4s linear;

        @include bp-767 {
          display: none;
        }
      }
    }

    // Text Position
    .text-wrapper {
      pointer-events: none;
      position: absolute;
      display: grid;
      @include vw(gap, 2px, 2px);
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      @include vw(padding, 20px);
      text-align: center;
      height: 100%;
      width: 90%;
      flex-direction: column;

      .overflow-hider {
        width: 100%;
      }

      @include bp-767 {
        color: var(--black);
        text-align: left;
      }

      h2 {
        transform: translateY(108%);
        transition: transform 0.6s var(--ease-in-out-cubic);
      }
    }

    @include bp-767 {
      .text-wrapper {
        position: relative;
        bottom: auto !important;
        left: auto !important;
        right: auto !important;
        top: auto !important;
        gap: 0;
        line-height: 1.1;
        margin-top: 8px;
        transform: initial;
      }
    }
  }
}

body {
  &.is-loaded {
    .home-projects {
      .project {
        &:first-child {
          // transform-origin: 50% 0;
          .image {
            transform: translateY(0);
          }
        }
      }
    }
  }
}

.home-about {
  position: relative;
  max-width: 100%;
  padding: 0 20px;
  @include vw(width, 476px, 476px);
  text-align: center;
  z-index: 4;
  overflow-x: initial;

  @include bp-767 {
    padding-left: 32px;
    padding-right: 32px;
  }

  .about-text {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    @include vw(margin-bottom, 62px, 48px);

    h2 {
      @include sans-headline-xl;
      @include vw(margin-bottom, 24px, 16px);
    }

    p,
    a {
      @include sans-text-m;
    }

    @include hover {
      .about-image {
        opacity: 1;

        .image {
          transform: scale(1);
        }
      }
    }

    @include bp-767 {
      max-width: 300px;
    }
  }

  h3 {
    @include sans-headline-l;
    @include vw(margin-bottom, 12px, 8px);
  }

  .clients-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @include vw(column-gap, 12px, 6px);
    @include vw(row-gap, 4px, 2px);
    @include vw(margin-bottom, 280px, 180px);

    li {
      @include serif-headline-m;

      &:nth-child(odd) {
        text-align: right;
      }

      &:nth-child(even) {
        text-align: left;
      }
    }
  }

  .awards-list {
    @include vw(width, 380px, 210px);
    max-width: 100%;
    @include vw(margin-bottom, 12px, 12px);
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    hr {
      width: 100%;
    }
  }

  .award {
    width: 33.333%;
    @include vw(padding, 6px, 4px);
    flex-shrink: 0;
    display: grid;
    place-items: center;

    @include bp-767 {
      width: 50%;
    }
  }
}

.about-image {
  position: absolute;
  top: -5%;
  left: -21vw;
  @include vw(width, 400px, 200px);
  max-width: 100vw;
  mix-blend-mode: difference;
  opacity: 0;
  transition: opacity 0.3s linear;
  pointer-events: none;

  .image {
    transform: scale(0.9);
    transition: transform 0.3s ease-out;
  }

  @include bp-767 {
    position: relative;
    top: 0;
    left: 0;
    opacity: 1;
    mix-blend-mode: initial;
    width: 80%;
    margin: 0 auto -1.3rem;
    z-index: -1;

    .image {
      transform: scale(1);
    }
  }
}
